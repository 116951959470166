import i18n from "../i18n";

const incomingstatuses = {
  "-3": {
    name: i18n.global.t("incomingstatuses.draft"),
    color: "bg-light text-body",
    id: -3,
    manualIncomingNames: {
      2: i18n.global.t("incomingstatuses.draft"),
      5: i18n.global.t("incomingstatuses.draft"),
      6: i18n.global.t("incomingstatuses.draft"),
    },
  },
  "-2": {
    name: i18n.global.t("incomingstatuses.waiting"),
    color: "badge-outline-primary text-black",
    id: -2,
    manualIncomingNames: {
      2: i18n.global.t("incomingstatuses.waiting"),
      3: i18n.global.t("incomingstatuses.waiting"),
      5: i18n.global.t("incomingstatuses.waiting"),
      6: i18n.global.t("incomingstatuses.waiting"),
    },
  },
  "-1": {
    name: i18n.global.t("incomingstatuses.underProduction"),
    color: "bg-info text-body",
    id: -1,
    manualIncomingNames: {
      2: i18n.global.t("incomingstatuses.waitingForThePartner"),
      3: i18n.global.t("incomingstatuses.underProduction"),
      5: i18n.global.t("incomingstatuses.waitingForThePartner"),
      6: i18n.global.t("incomingstatuses.underProduction"),
    },
  },
  0: {
    name: i18n.global.t("incomingstatuses.finishProduction"),
    color: "warning text-black",
    id: 0,
    manualIncomingNames: {
      2: i18n.global.t("incomingstatuses.waitingForThePartner"),
      3: i18n.global.t("incomingstatuses.finishProduction"),
      5: i18n.global.t("incomingstatuses.waitingForThePartner"),
      6: i18n.global.t("incomingstatuses.finishProduction"),
    },
  },
  1: {
    name: i18n.global.t("incomingstatuses.underPackaging"),
    color: "bg-info text-body",
    id: 1,
    manualIncomingNames: {
      2: i18n.global.t("incomingstatuses.underPackaging"),
      3: i18n.global.t("incomingstatuses.underPackaging"),
      5: i18n.global.t("incomingstatuses.underPackaging"),
      6: i18n.global.t("incomingstatuses.underPackaging"),
    },
  },
  2: {
    name: i18n.global.t("incomingstatuses.waitForDelivery"),
    color: "bg-info-2 text-body",
    id: 2,
    manualIncomingNames: {
      2: i18n.global.t("incomingstatuses.waitForDelivery"),
      3: i18n.global.t("incomingstatuses.waitForDelivery"),
      5: i18n.global.t("incomingstatuses.waitForDelivery"),
      6: i18n.global.t("incomingstatuses.waitForDelivery"),
    },
  },
  3: {
    name: i18n.global.t("incomingstatuses.underDelivery"),
    color: "bg-primary text-white",
    id: 3,
    manualIncomingNames: {
      2: i18n.global.t("incomingstatuses.underDelivery"),
      3: i18n.global.t("incomingstatuses.underDelivery"),
      5: i18n.global.t("incomingstatuses.underDelivery"),
      6: i18n.global.t("incomingstatuses.underDelivery"),
    },
  },
  4: {
    name: i18n.global.t("incomingstatuses.onHold"),
    color: "bg-warning text-dark",
    id: 4,
    manualIncomingNames: {
      2: i18n.global.t("incomingstatuses.onHold"),
      3: i18n.global.t("incomingstatuses.onHold"),
      5: i18n.global.t("incomingstatuses.onHold"),
      6: i18n.global.t("incomingstatuses.onHold"),
    },
  },
  5: {
    name: i18n.global.t("incomingstatuses.delivered"),
    color: "bg-secondary text-white",
    id: 5,
    manualIncomingNames: {
      2: i18n.global.t("incomingstatuses.partialFulfillment"),
      3: i18n.global.t("incomingstatuses.delivered"),
      5: i18n.global.t("incomingstatuses.partialFulfillment"),
      6: i18n.global.t("incomingstatuses.delivered"),
    },
  },
  6: {
    name: i18n.global.t("incomingstatuses.finished"),
    color: "bg-success text-white",
    id: 6,
    manualIncomingNames: {
      2: i18n.global.t("incomingstatuses.finished"),
      3: i18n.global.t("incomingstatuses.finished"),
      5: i18n.global.t("incomingstatuses.finished"),
      6: i18n.global.t("incomingstatuses.finished"),
    },
  },
  7: {
    name: i18n.global.t("incomingstatuses.cancelled"),
    color: "bg-danger text-white",
    id: 7,
    manualIncomingNames: {
      2: i18n.global.t("incomingstatuses.cancelled"),
      3: i18n.global.t("incomingstatuses.cancelled"),
      5: i18n.global.t("incomingstatuses.cancelled"),
      6: i18n.global.t("incomingstatuses.cancelled"),
    },
  },
};

export default incomingstatuses;
