<template>
  <div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <div class="row">
            <TopBar
              v-model:selectAll="selectAll"
              v-model:line-options="lineOptions"
              v-model:rows="rows"
              v-model:items="items"
              v-model:selectedLineOption="selectedLineOption"
              :show-delete="false"
              :show-select="false"
              :show-currencies="false"
              :show-add="false"
              :show-languages="false"
              :export-url="getUrl(true)"
            />
            <DynamicTable
              v-model:rows="rows"
              v-model:items="items"
              v-model:select-all="selectAll"
              type="products"
              :key="tableKey"
              :show-pin="false"
              :show-order="false"
              :show-select="false"
              :fix-height="true"
              v-on:loadMore="loadMore"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "../../components/lists/TopBar";
import DynamicTable from "../../components/lists/DynamicTable";
import { useStore } from "vuex";
import http from "../../modules/http";
import lineOptions from "../../modules/lineOptions";
import incomingstatuses from "../../modules/Incomingstatuses";

export default {
  name: "Incoming",
  components: { DynamicTable, TopBar },
  data() {
    return {
      store: useStore(),
      tableKey: 0,
      addNew: false,
      rows: [],
      selectedLineOption: 1,
      lineOptions: [],
      selectAll: false,
      items: [],
      filtersTimeOut: null,
      firstLoad: true,
      page: 1,
    };
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    date() {
      return this.store.state.topBar.date;
    },
    q() {
      return this.store.state.topBar.q;
    },
    filterPage() {
      return this.store.state.filterPage;
    },
    filters() {
      return this.store.state.filters?.incomings;
    },
  },
  watch: {
    warehouse() {
      this.loadData();
    },
    q() {
      if (this.filtersTimeOut !== null) {
        clearTimeout(this.filtersTimeOut);
      }
      this.filtersTimeOut = setTimeout(() => {
        this.filtersTimeOut = null;
        this.page = 1;
        this.loadData();
      }, 450);
    },
    filters: {
      deep: true,
      handler() {
        if (this.filterPage !== "incomings" || this.firstLoad) {
          setTimeout(() => {
            this.firstLoad = false;
          }, 100);
          return;
        }
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.page = 1;
          this.loadData();
        }, 450);
      },
    },
    date: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    getUrl(exports = false) {
      let url =
        `/warehouses/income${exports ? "/export/{type}" : ""}?page=${
          exports ? 1 : this.page
        }` +
        (this.warehouse ? "&warehouse=" + this.warehouse : "") +
        (this.q ? "&q=" + this.q : "") +
        (this.date[0]
          ? "&from=" + this.date[0].toISOString().split("T")[0]
          : "") +
        (this.date[1] ? "&to=" + this.date[1].toISOString().split("T")[0] : "");

      if (this.filterPage !== "incomings") {
        url += `&status[]=-3&status[]=-2&status[]=-1&status[]=0&status[]=1&status[]=2&status[]=3&status[]=4&status[]=5&status[]=6`;
      } else if (Object.keys(this.filters)?.length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&${filter}[]=${value}`;
            }
          } else {
            url += `&${filter}=${this.filters[filter].value}`;
          }
        }
      }

      return url;
    },
    loadData() {
      if (this.page === 1) {
        this.items = null;
      }
      if (this.filterPage !== "incomings") {
        this.$store.commit("setFilterOptions", []);

        http
          .fetch("/products/products/characteristics?language=en")
          .then((data) => {
            let filterOptions = [];
            this.$store.commit("setFilterPage", "incomings");

            filterOptions.push({
              name: "Status",
              type: "string",
              key: "status",
              value: {
                "-3": true,
                "-2": true,
                "-1": true,
                0: true,
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: true,
              },
              values: Object.values(incomingstatuses),
              valueName: "name",
            });

            this.filters["status"] = {
              value: ["-3", "-2", "-1", 0, 1, 2, 3, 4, 5, 6],
            };

            filterOptions.push({
              name: "Categories",
              type: "string",
              key: "categories",
              value: {},
              values: this.categories,
              valueName: "name",
            });

            for (const obj of data) {
              let max =
                obj.characteristics_values_max_val_double !== null
                  ? obj.characteristics_values_max_val_double
                  : obj.characteristics_values_max_val_int;
              filterOptions.push({
                name: obj.name,
                type: obj.type,
                key: "characteristics[" + obj.id + "]",
                value: obj.type === "number" ? [0, max] : {},
                max: max,
                values: obj?.characteristics_values,
                prefix: obj?.prefix,
                postfix: obj?.postfix,
              });
            }

            this.$store.commit("setFilterOptions", filterOptions);
            this.$store.commit("setFilterOptionsStock", false);
            this.$store.commit("setFilterOptionsPrice", false);
          });
      }
      http.fetch(this.getUrl()).then((data) => {
        if (this.page === 1) {
          this.rows = lineOptions.init(data.rows, data.line_options);
          this.lineOptions = data.line_options;
          this.selectedLineOption = data.line_options[0].id;

          this.rows.find((e) => e.key === "warehouse_outcome_id").values =
            this.warehouses;
          this.rows.find((e) => e.key === "warehouse_income_id").values =
            this.warehouses;

          let status = this.rows.find((e) => e.key === "status");
          status.values = incomingstatuses;
          status.center = true;

          this.items = data.data;
          this.tableKey++;
        } else {
          for (const row of data.data) {
            this.items.push(row);
          }
        }
      });
    },
    loadMore() {
      this.page++;
      this.loadData();
    },
  },
};
</script>
